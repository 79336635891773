<nav class="navbar navbar-expand-lg navbar-light bg-light" *ngIf="account && account.role === 'User'">
  <div class="container-fluid">
    <!-- Brand as Toggle Button -->
    <a class="navbar-brand d-flex align-items-center justify-content-center mx-auto" href="#" (click)="toggleNavbar()">
      <img src="assets/img/pv_logot.png" alt="Brand Logo" class="brand-img">
    </a>

    <!-- Toggle button for mobile view -->
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" style="display: none;">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- Navbar items -->
    <div class="collapse navbar-collapse" id="navbarCollapse" [ngClass]="{ 'show': isNavbarVisible }">
      <ul class="navbar-nav mx-auto">
        <!-- Notifications Dropdown -->
        <li class="nav-item dropdown">
          <button class="btn btn-light dropdown-toggle notification-btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-bell"></i>
            <span class="notification-count" *ngIf="unreadCount > 0">{{ unreadCount }}</span>
          </button>
          <ul class="dropdown-menu notifications-box dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
            <!-- Notifications List -->
            <li *ngFor="let notification of notifications">
              <div (click)="goto(notification)" [ngClass]="{
                  'notification-item': true,
                  'old-notification': notification.isRead,
                  'new-notification': !notification.isRead
                }">
                <div class="notification-content">
                  <div class="notification-header">
                    <i class="bi bi-dot" *ngIf="!notification.isRead"></i>
                    <span class="notification-title">{{ notification.title }}</span>
                    <span class="notification-date">{{ notification.date | date : 'short' }}</span>
                  </div>
                  <div class="notification-message">
                    {{ notification.message }}
                  </div>
                </div>
              </div>
            </li>
            <!-- Empty State -->
            <div *ngIf="notifications.length === 0" class="no-notifications">You have no notifications</div>
          </ul>
        </li>
      </ul>

      <!-- Right side: Language Dropdown and Logout -->
      <ul class="navbar-nav ms-auto">
        <!-- Language Dropdown -->
        <li class="nav-item dropdown">
          <button class="btn btn-light dropdown-toggle" type="button" id="languageDropdown" data-bs-toggle="dropdown"
            aria-expanded="false">
            <img [src]="currentLanguageFlag" alt="Language" class="flag-icon" />
          </button>
          <ul class="dropdown-menu" aria-labelledby="languageDropdown">
            <li>
              <a class="dropdown-item" (click)="changeLanguage('ar')">
                <img src="assets/flags/ar.png" alt="Arabic" class="flag-icon" /> العربية
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="changeLanguage('fr')">
                <img src="assets/flags/fr.png" alt="French" class="flag-icon" /> Français
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="changeLanguage('en')">
                <img src="assets/flags/en.png" alt="English" class="flag-icon" /> English
              </a>
            </li>
          </ul>
        </li>

        <!-- Logout Button -->
        <li class="nav-item">
          <button class="logout-btn" (click)="logout()">{{ 'logout' | translate }}</button>
        </li>
      </ul>
    </div>
  </div>
</nav>
