<p>add-link works!</p>
<div class="contain">
  <div class="login-box">
    <div class="login-header">
      <header>Register through link</header>
    </div>
    <form *ngIf="!loading" [formGroup]="form" (ngSubmit)="onSubmit()">
      <!-- Fullname field -->
      <div class="input-box">
        <input type="text" formControlName="fullName" class="input-field" placeholder="Full Name"
          [ngClass]="{ 'is-invalid': submitted && f['fullName'].errors }" />
        <div *ngIf="submitted && f['fullName'].errors" class="invalid-feedback">
          <div *ngIf="f['fullName'].errors['required']">First Name is required</div>
        </div>
      </div>
      <!-- Username field -->
      <div class="input-box">
        <input type="text" formControlName="username" class="input-field" placeholder="Username"
          [ngClass]="{ 'is-invalid': submitted && f['username'].errors }" />
        <div *ngIf="submitted && f['username'].errors" class="invalid-feedback">
          <div *ngIf="f['username'].errors['required']">Username is required</div>
        </div>
      </div>
      
      <!-- CIN field -->
      <div class="input-box">
        <input type="text" formControlName="cin" class="input-field" placeholder="CIN"
          [ngClass]="{ 'is-invalid': submitted && f['cin'].errors }" />
        <div *ngIf="submitted && f['cin'].errors" class="invalid-feedback">
          <div *ngIf="f['cin'].errors['required']">CIN is required</div>
        </div>
      </div>
      <!-- Moderator field -->
      <div *ngIf="showModeratorField" class="input-box">
        <label class="form-label">Region to control:</label>
        <select formControlName="moderatorZone" class="input-field"
          [ngClass]="{ 'is-invalid': submitted && f['moderatorZone'].errors }">
          <option [value]="actualReg ? actualReg._id : ''">{{ actualReg ? actualReg.name : 'Select Region' }}</option>
          <option *ngFor="let region of regions" [value]="region._id">{{region.name}}</option>
        </select>
        <div *ngIf="submitted && f['moderatorZone'].errors" class="invalid-feedback">
          <div *ngIf="f['moderatorZone'].errors['required']">A region is required</div>
        </div>
      </div>
      <!-- City field -->
      <div *ngIf="showUserField" class="input-box">
        <label class="form-label">City:</label>
        <select formControlName="city" class="input-field" [ngClass]="{ 'is-invalid': submitted && f['city'].errors }">
          <option [value]="actualCity ? actualCity._id : ''">{{ actualCity ? actualCity.name : 'Select City' }}</option>
          <option *ngFor="let city of cities" [value]="city._id">{{city.name}}</option>
        </select>
        <div *ngIf="submitted && f['city'].errors" class="invalid-feedback">
          <div *ngIf="f['city'].errors['required']">City is required</div>
        </div>
      </div>
      <!-- Password field -->
      <div class="input-box">
        <input type="password" formControlName="password" class="input-field" placeholder="Password"
          [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" />
        <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
          <div *ngIf="f['password'].errors['required']">Password is required</div>
          <div *ngIf="f['password'].errors['minlength']">Password must be at least 6 characters</div>
        </div>
      </div>
      <!-- Confirm Password field -->
      <div class="input-box">
        <input type="password" formControlName="confirmPassword" class="input-field" placeholder="Confirm Password"
          [ngClass]="{ 'is-invalid': submitted && f['confirmPassword'].errors }" />
        <div *ngIf="submitted && f['confirmPassword'].errors" class="invalid-feedback">
          <div *ngIf="f['confirmPassword'].errors['required']">Confirm Password is required</div>
          <div *ngIf="f['confirmPassword'].errors['mustMatch']">Passwords must match</div>
        </div>
      </div>
      <div class="input-submit">
        <button class="submit-btn" [disabled]="submitting">
          <span *ngIf="submitting" class="spinner-border spinner-border-sm me-1"></span>
          Save
        </button>
        <a routerLink="../" class="btn btn-link">Cancel</a>
      </div>
    </form>
    <div *ngIf="loading" class="text-center m-5">
      <span class="spinner-border spinner-border-lg align-center"></span>
    </div>
  </div>
</div>