<div class="contain">
    <div class="login-box">
        <div class="login-header">
            <header>الشعب يريد</header>
        </div>
        <div class="input-submit" *ngIf="!account">
            <button class="submit-btn" id="submit" routerLink="/account/login"></button>
            <label for="submit">تسجيل الدخول</label>
        </div>
    </div>
</div>