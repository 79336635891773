import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';


@Component({ selector: 'app-back-button', templateUrl: 'back-button.component.html', styleUrls: ['back-button.component.css'] })
export class BackButtonComponent {

  currentLanguage: string;

  constructor(private location: Location, private translateService: TranslateService) {
    this.currentLanguage = this.translateService.currentLang;
  }

  ngOnInit() {
    // Listen for language changes
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLanguage = event.lang;
    });
  }

  goBack(): void {
    this.location.back();
  }

}