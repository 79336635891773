<footer id="footer" class="footer position-relative" *ngIf="account?.role !== Role.Admin || false">

    <div class="container footer-top">
      <div class="row gy-4">
        <div class="col-lg-4 col-md-6 footer-about align-items-center justify-content-center">
          <a routerLink="/" class="logo">
            <img [src]="'assets/img/MegaLearnLOGO.jpg'" alt="">
          </a>
          <div class="footer-contact pt-3">
            <p>MegaLearn Online education</p>
            <p><strong>Email:</strong> <span> hello&#64;megalearn.com</span></p>
          </div>
          <div class="social-links d-flex mt-4">
            <a routerLink="/"><i class="bi bi-twitter-x"></i></a>
            <a routerLink="/"><i class="bi bi-facebook"></i></a>
            <a routerLink="/"><i class="bi bi-instagram"></i></a>
            <a routerLink="/"><i class="bi bi-linkedin"></i></a>
          </div>
        </div>

        <div class="col-lg-2 col-md-3 footer-links ">
          <h4>Useful Links</h4>
          <ul class="align-items-center justify-content-center text-center">
            <li><a routerLink="/">Home</a></li>
            <li><a routerLink="courses">Courses</a></li>
            <li><a routerLink="claim">Claim</a></li>
            <li><a routerLink="/">Terms of service</a></li>
            <li><a routerLink="/">Privacy policy</a></li>
          </ul>
        </div>

        <div class="col-lg-4 col-md-12 footer-newsletter">
          <h4>Our Newsletter</h4>
          <p>Subscribe to our newsletter and receive the latest news about our products and services!</p>
          <form action="forms/newsletter.php" method="post" class="php-email-form">
            <div class="newsletter-form"><input type="email" name="email"><input type="submit" value="Subscribe"></div>
            <div class="loading">Loading</div>
            <div class="error-message"></div>
            <div class="sent-message">Your subscription request has been sent. Thank you!</div>
          </form>
        </div>

      </div>
    </div>

    <div class="container copyright text-center mt-4">
      <p>© <span>Copyright</span> <strong class="px-1 sitename">MegaLean</strong> <span>All Rights Reserved</span></p>
      <div class="credits">
        Designed by <a href="https://uvmatrix.space/" target="_blank">uvmatrix</a>
      </div>
    </div>

  </footer>